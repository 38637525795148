<template>
    <ValidationProvider rules="validimage" ref="image" v-slot="{ errors, validate,ariaInput,ariaMsg,classes}">
        <div class="image-input" :class="classes">
            <input type="file" :id="id" :name="name" @change="handleFileChange">
            <template v-if="!!!imagetoshow">
                <p>Arrastrar y soltar o <a class="btn -white">Selecciona archivos</a></p>
                <span class="tip">.jpg .jpeg .png Tamaño máximo 2MB</span>
            </template>

            <span v-if="!!imagetoshow" :style="`background:url(${imagetoshow}) center/cover no-repeat`" class="imagen-avatar"></span>

            <a v-if="!!imagetoshow" class="btn">Cambiar</a>

            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
        </div>

        <div class="cropper-container" v-if="show">
            <div class="popup">

                <cropper v-if="!!originalimage" classname="cropper" :src="originalimage" :stencil-props="{
                    aspectRatio: 1/1
                }" @change="change" :stencilComponent="$options.components.CircleStencil" :resizeImage="true"></cropper>
                <CircleStencil v-if="false"></CircleStencil>

                <div class="contenedor-botones row-center">
                    <a class="boton-azul" @click="save">Guardar</a>
                    <a class="boton-gris">Cancelar</a>
                </div>


            </div>
        </div>

    </ValidationProvider>
</template>

<script>


    import { Cropper, CircleStencil } from 'vue-advanced-cropper'
    import 'vue-advanced-cropper/dist/style.css';

    export default {
        name: 'avatar-input',
        props: ['id', 'name', 'required','value'],
        data: () => ({
            originalimage: '',
            data: '',
            show: false,
            new : false,
            imagetoshow : '',
        }),
        methods: {
            async handleFileChange(e) {
                const { valid } = await this.$refs.image.validate(e);
                if (valid) {
                    this.setData(e);
                }
            },
            setData(event) {

                Array.from(event.target.files).forEach(file => {
                    this.getBase64(file).then(data => {
                        this.originalimage = data;
                        this.show = true;
                    });

                });
            },
            getBase64: function (file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
            },
            change({ canvas }) {
                this.data = canvas.toDataURL();
                this.imagetoshow = this.data;
            },
            save() {
                this.new = true;
                this.emit();
                this.show = false;
            },
            emit() {
                let data = this.data;
                this.$emit('input', data.replace(/^data:image\/(png|jpg|jpeg|gif);base64,/, ""));
                this.$emit('change');
            }
        },
        components: {
            Cropper,
            CircleStencil,
        },
        watch : {
            'value'(){
                if(!this.new){
                    this.imagetoshow = this.value.url;
                }
            }
        }
    }
</script>