<template>

    <div class="cover-login js-registro">

        <div class="contenedor-popup row-center">


            <div class="popup">

                <div class="paso">

                    <h2 class="titulo left">Mis datos familiares</h2>


                    <div class="toggle">
                        <input type="checkbox" id="familiar_bowl" v-model="dataalumni.familiar_bowl" @change="requestSaveAlumni">
                        <label for="familiar_bowl">Soy familiar de un alumno de la UFV</label>
                    </div>

                    <template v-if="dataalumni.familiar_bowl">

                        <familiar :values="el" :key="key" v-for="(el,key) in data" @refresh="setData"></familiar>

                        <p v-if="data.length == 0 && !shownew">Aún no tienes ningún dato añadido</p>

                        <div class="full-width row-center contenedor-boton" v-if="!shownew">
                            <a class="boton-azul" @click="shownew = true" :loading="loading">Añadir nuevo</a>
                        </div>

                        <familiar :showedit="shownew" v-if="shownew" @refresh="setData"></familiar>

                    </template>

                </div>
            </div>

        </div>
    </div>

</template>

<script>

    import { mapGetters, mapActions } from 'vuex';

    import router from '@/router';

    import familiar from '@/components/familiar-el'

    export default {
        name: 'mis-datos-familiares',
        mounted() {
            this.setData();
        },
        data: () => ({
            data: {

            },
            dataalumni: {},
            loading: false,
            shownew: false
        }),
        computed: {
            ...mapGetters({
                dominios: 'getDominios'
            })
        },
        methods: {
            ...mapActions(['saveFamiliar', 'getFamiliar', 'saveAlumni', 'getAlumni']),
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        this.saveFamiliar(this.data).then(r => {
                            this.loading = false;
                            if (r) {
                                router.push({
                                    name: 'login'
                                })
                            }
                        })
                    }
                });
            },
            setData() {
                this.shownew = false;

                this.data = [];

                this.getFamiliar().then(r => {
                    this.data = r;
                });

                this.getAlumni().then(r => {
                    this.dataalumni = r;
                });
            },
            requestSaveAlumni() {
                this.saveAlumni(this.dataalumni);
            }
        },
        components: {
            'familiar': familiar
        }
    }
</script>

<style lang="scss" scoped>
    .js-registro .popup {
        width: 100%;
    }
</style>