<template>
    <div class="wrapper-registro cover-login">
        <div class="popup">
            <ValidationObserver ref="form" v-slot="{failed,valid}">
                <form class="paso row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="off">
                    <h2 class="titulo left">Establecer contraseña</h2>

                    
                    <div class="contenedor-input">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="mail" placeholder="DNI" v-model="data.document"
                                :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>


                    <div class="contenedor-input">
                        <ValidationProvider :rules="'required|min:8'" v-slot="{ errors,ariaInput,ariaMsg,classes }"
                            vid="password">
                            <input autocomplete="off" type="password" name="pass" placeholder="Contraseña"
                                v-model="data.password" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="contenedor-input">
                        <ValidationProvider :rules="'required|confirmed:password|min:8'"
                            v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="password" name="repite-pass"
                                placeholder="Repite tu contraseña" v-model="data.password_confirmation"
                                :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>



                    <div class="full-width row-center">
                        <button class="boton-azul" :loading="loading">Guardar</button>
                    </div>



                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>

    import { mapActions } from 'vuex';

    import router from '@/router';

    export default {
        name: 'set-password',

        mounted() {
            if (this.$route.query.token) {
                this.data.token = this.$route.query.token;
            } else {
                router.push({
                    name: 'login'
                })
            }
        },

        data: () => ({
            data: {
                'password': '',
                'password_confirmation': '',
                'document' : ''
            },
            loading: false
        }),

        methods: {
            ...mapActions(['sendPass']),
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        this.sendPass(this.data).then(r => {
                            this.loading = false;
                            if (r) {
                                router.push({
                                    name: 'login'
                                })
                            }
                        })
                    }
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    .cover-login .popup {
        max-width: 400px;
    }
</style>