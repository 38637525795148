<template>
    <div class="microform" :edit="edit">

        <template v-if="!edit && data">
            <h3>{{data.type.name}}</h3>
            <h2>{{data.comment}}</h2>
        </template>

        <a class="editar" v-if="data.dataId" @click="edit = true">editar</a>
        <a class="eliminar" v-if="data.dataId" @click="showerase = true">Eliminar</a>


        <template v-if="edit">

            <ValidationObserver ref="form" v-slot="{failed,valid}">

                <form class="paso row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="off">

                    <div class="contenedor-input">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <autocomplete :class="classes" :name="'personal_type'" :label="'Relación'"
                                :type="'personal_type'" v-model="data.type" :idkey="'id'"></autocomplete>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
    
    
    
                    <div class="contenedor-input">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <label for="comment">Indica nombre y apellidos de tu familiar o amigo en la UFV*</label>
                            <textarea name="comment" id="comment" v-model="data.comment" :fill="!!data.comment"
                                :class="classes"></textarea>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
    

                    <div class="full-width row-center">
                        <button class="boton-azul" :loading="loading">Guardar</button>
                    </div>


                </form>

            </ValidationObserver>

        </template>



        <popup-confirmacion v-if="showerase" :loading="loadingerase" :how="'borrarfamiliar'" @accept="requestErase"
            @cancel="showerase = false" :isnegative="true">
        </popup-confirmacion>


    </div>
</template>


<script>

    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'familiar',
        props: ['values', 'showedit'],
        mounted() {
            if (this.values) {
                this.data = this.values;
            }

            this.edit = this.showedit;
        },
        data: () => ({
            data: {
                type : '',
                comment : ''
            },
            edit: false,
            loading: false,
            loadingerase: false,
            showerase: false
        }),
        methods: {
            ...mapActions(['deleteFamiliar', 'saveFamiliar']),
            requestErase() {
                let data = [this.data.dataId];
                data = { dataId: data }
                this.deleteFamiliar(data).then(r => {
                    if (r) {
                        this.$emit('refresh');
                        this.showerase = false;
                    }
                })
            },
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;


                        let data = JSON.parse(JSON.stringify(this.data))

                        if (typeof data.type == 'object') {
                            data.type = data.personal_type_id;
                        }

                        this.saveFamiliar(data).then(r => {
                            this.loading = false;
                            if (r) {
                                this.edit = false;
                                this.$emit('refresh')
                            }
                        })
                    }
                });
            },
            cancel() {
                this.$emit('cancel');
                this.edit = false;
            }
        },
        computed: {
            ...mapGetters({
                dominios: 'getDominios'
            })
        }


    }
</script>