<template>
    <div class="wrapper-registro cover-login">
        <div class="popup">
            <ValidationObserver ref="form" v-slot="{failed,valid}">
                <form class="paso row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="off">
                    <h2 class="titulo left">Recordar contraseña</h2>

                    <div class="contenedor-input">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="document" placeholder="DNI" v-model="data.document"
                                :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="email" name="mail" placeholder="Email" v-model="data.email"
                                :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>



                    <div class="full-width row-center">
                        <button class="boton-azul" :loading="loading">Solicitar</button>
                    </div>



                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>

    import { mapActions } from 'vuex';

    import router from '@/router';

    export default {
        name: 'registro',
        data: () => ({
            data: {
                'document': '26771364P',
                'email': 'ipal.omarv@gmail.com',
            },
            loading: false
        }),

        methods: {
            ...mapActions(['requestRecoveryPass']),
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        this.requestRecoveryPass(this.data).then(r => {
                            this.loading = false;
                            if (r) {
                                router.push({
                                    name : 'login'
                                })
                            }
                        })
                    }
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    .cover-login .popup {
        max-width: 400px;
    }
</style>