<template>
    <div class="wrapper-registro cover-login">
        <div class="popup">
            <ValidationObserver ref="form" v-slot="{failed,valid}">
                <form class="paso row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="off">
                    <h2 class="titulo left">Iniciar sesión</h2>

                    <div class="contenedor-input">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="mail" placeholder="DNI" v-model="data.document"
                                :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>



                    <div class="contenedor-input">
                        <ValidationProvider :rules="'required|min:8'" v-slot="{ errors,ariaInput,ariaMsg,classes }"
                            vid="password">
                            <input autocomplete="off" type="password" name="pass" placeholder="Contraseña"
                                v-model="data.password" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>



                    <div class="full-width row-center">
                        <button class="boton-azul" :loading="loading">Acceder</button>
                    </div>


                    <router-link to="/solicitar-contrasena" class="recordar-pass">He olvidado mi contraseña</router-link>

                    <router-link id="aRegistro" to="/es/general/registro" class="acceso-registro"><span>¿Aún no estás
                            registrado?</span></router-link>


                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>

    import { mapActions } from 'vuex';


    import router from '@/router';

    export default {
        name: 'registro',
        data: () => ({
            data: {
                'document': '26771364P',
                'password': '12345678',

            },
            loading: false
        }),

        methods: {
            ...mapActions(['login']),
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        this.login(this.data).then(r => {
                            this.loading = false;
                            if (r) {
                                router.push({
                                    name : 'home'
                                })
                            }
                        })
                    }
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    .cover-login .popup {
        max-width: 400px;
    }
</style>