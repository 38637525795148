<template>

    <div class="cover-login js-registro">

        <div class="contenedor-popup row-center">


            <div class="popup">

                <div class="paso">

                    <h2 class="titulo left">Mis datos profesionales</h2>


                    <div class="contenedor-input half row-start fww" v-if="dataalumni.working">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <h5>Situación laboral</h5>
                            <input type="radio" :value="'0'" name="situacion" id="paro" v-model="dataalumni.working"
                                @change="requestSaveAlumni">
                            <label :class="classes" for="paro">En paro</label>
                            <input type="radio" :value="'1'" name="situacion" id="activo" v-model="dataalumni.working"
                                @change="requestSaveAlumni">
                            <label :class="classes" for="activo">Activo</label>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>


                    <profesional :values="el" :key="key" v-for="(el,key) in data" @refresh="setData"></profesional>

                    <p v-if="data.length == 0 && !shownew">Aún no tienes ningún dato añadido</p>

                    <div class="contenedor-boton full-width row-center" v-if="!shownew">
                        <a class="boton-azul" @click="shownew = true" :loading="loading">Añadir nuevo</a>
                    </div>

                    <profesional v-if="shownew" :showedit="true" @cancel="shownew = false" @refresh="setData">
                    </profesional>

                </div>
            </div>

        </div>
    </div>

</template>

<script>

    import { mapGetters, mapActions } from 'vuex';

    import profesional from '@/components/profesional-el'

    export default {
        name: 'mis-datos-profesionales',
        mounted() {
            this.setData();
        },
        data: () => ({
            data: {

            },
            dataalumni: {
                working : ''
            },
            loading: false,
            shownew: false
        }),
        computed: {
            ...mapGetters({
                dominios: 'getDominios'
            })
        },
        methods: {
            ...mapActions(['saveAlumni', 'getAlumni', 'getProfesionales']),
            setData() {
                this.shownew = false;
                this.getProfesionales().then(r => {
                    this.data = r;
                });

                this.getAlumni().then(r => {
                    this.dataalumni.working = String(r.working);
                });

            },
            requestSaveAlumni() {
                this.saveAlumni(this.dataalumni);
            }
        },
        components: {
            'profesional': profesional
        }
    }
</script>


<style lang="scss" scoped>
    .js-registro .popup {
        width: 100%;
    }
</style>