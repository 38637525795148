<template>
    <div class="microform" :edit="edit">

        <template v-if="!edit && data">
            <h3>{{dominios.nivelesidioma[data.level]}}</h3>
            <h2>{{data.lang.name}}</h2>
        </template>

        <a class="editar" v-if="data.dataId" @click="edit = true">editar</a>
        <a class="eliminar" v-if="data.dataId" @click="showerase = true">Eliminar</a>


        <template v-if="edit">

            <ValidationObserver ref="form" v-slot="{failed,valid}">

                <form class="paso row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="off">

                    <div class="contenedor-input -middle">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <autocomplete :class="classes"  :name="'langs'" :label="'Idioma'"
                                :type="'langs'" v-model="data.lang" :idkey="'id'"></autocomplete>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    
                    <div class="contenedor-input -middle">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <select id="nivelidioma" name="nivelidioma" v-model="data.level" class="pretty"
                            :class="classes">
                            <option name="myBrowser" placeholder="" value="">Nivel</option>
                            <option :key="key" v-for="(idioma,key) in dominios.nivelesidioma" name="myBrowser" :value="key">
                                {{idioma}}</option>
                        </select>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="full-width row-center">
                        <button class="boton-azul" :loading="loading">Guardar</button>
                    </div>


                </form>

            </ValidationObserver>

        </template>



        <popup-confirmacion v-if="showerase" :loading="loadingerase" :how="'borraridioma'" @accept="requestErase"
            @cancel="showerase = false" :isnegative="true">
        </popup-confirmacion>


    </div>
</template>


<script>

    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'idioma',
        props: ['values', 'showedit'],
        mounted() {
            if (this.values) {
                this.data = this.values;
                
            }

            this.edit = this.showedit;
        },
        data: () => ({
            data: {
                'lang' : '',
                'level' : '',
            },
            edit: false,
            loading: false,
            loadingerase: false,
            showerase: false
        }),
        methods: {
            ...mapActions(['deleteIdioma', 'saveIdioma']),
            requestErase() {
                let data = [this.data.dataId];
                data = { dataId: data }
                this.deleteIdioma(data).then(r => {
                    if (r) {
                        this.$emit('refresh');
                        this.showerase = false;
                    }
                })
            },
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;


                        let data = JSON.parse(JSON.stringify(this.data))

                        if (typeof data.lang == 'object') {
                            data.lang = data.lang_id;
                        }

                        this.saveIdioma(data).then(r => {
                            this.loading = false;
                            if (r) {
                                this.edit = false;
                                this.$emit('refresh')
                            }
                        })
                    }
                });
            },
            cancel() {
                this.$emit('cancel');
                this.edit = false;
            }
        },
        computed: {
            ...mapGetters({
                dominios: 'getDominios'
            })
        }


    }
</script>