<template>
    <div class="wrapper-registro cover-login">
        <div class="popup">
            <ValidationObserver ref="form" v-slot="{failed,valid}">
                <form class="paso row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="off">
                    <h2 class="titulo left">Mis datos elumni</h2>

                    <div class="contenedor-input half row-start fww">
                        <h5>¿Eres miembro Clavis?</h5>
                        <input type="radio" :value="1" name="clavis" id="clavis_si" v-model="data.key_member">
                        <label for="clavis_si">Sí</label>
                        <input type="radio" :value="0" name="clavis" id="clavis_no" v-model="data.key_member">
                        <label for="clavis_no">No</label>
                    </div>


                    <div class="contenedor-input row-start fww">
                        <h5>Miembro de tribunal PF ELU</h5>
                        <input type="radio" :value="1" name="member_elu_tribunal" id="member_elu_tribunal_si"
                            v-model="data.member_elu_tribunal">
                        <label for="member_elu_tribunal_si">Sí</label>
                        <input type="radio" :value="0" name="member_elu_tribunal" id="member_elu_tribunal_no"
                            v-model="data.member_elu_tribunal">
                        <label for="member_elu_tribunal_no">No</label>
                    </div>

                    <div class="contenedor-input" v-if="data.member_elu_tribunal == 1">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <year-datepicker :class="classes" :label="'¿En que año?'" :minpage="new Date()"
                                v-model="data.member_elu_tribunal_date" :name="'member_elu_tribunal_date'">
                            </year-datepicker>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input row-start fww">
                        <h5>Participa en FdS Selección BE</h5>
                        <input type="radio" :value="1" name="fds_selection_be" id="fds_selection_be_si"
                            v-model="data.fds_selection_be">
                        <label for="fds_selection_be_si">Sí</label>
                        <input type="radio" :value="0" name="fds_selection_be" id="fds_selection_be_no"
                            v-model="data.fds_selection_be">
                        <label for="fds_selection_be_no">No</label>
                    </div>




                    <div class="contenedor-input row-start fww" v-if="data.fds_selection_be">
                        <h5>Tipo participación</h5>
                        <input type="radio" id="tipoparticipacion-0" name="tipoparticipacion" :value="0"
                            v-model="data.fds_select_be_as">
                        <label for="tipoparticipacion-0">Entrevistador</label>

                        <input type="radio" id="tipoparticipacion-1" name="tipoparticipacion" :value="1"
                            v-model="data.fds_select_be_as">
                        <label for="tipoparticipacion-1">Miembro de Tribunal</label>
                    </div>


                    <div class="contenedor-input" v-if="data.fds_selection_be">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <year-datepicker :class="classes" :label="'¿En que año?'" :minpage="new Date()"
                                v-model="data.fds_selection_be_date" :name="'fds_selection_be_date'">
                            </year-datepicker>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>



                    <div class="contenedor-input row-start fww">
                        <h5>Ponente</h5>
                        <input type="radio" :value="1" name="ponente" id="ponente_si" v-model="data.speaker">
                        <label for="ponente_si">Sí</label>
                        <input type="radio" :value="0" name="ponente" id="ponente_no" v-model="data.speaker">
                        <label for="ponente_no">No</label>
                    </div>


                    <div class="contenedor-input row-start fww" v-if="data.speaker">
                        <h5>Tipo de ponente:</h5>
                        <div class="full" v-for="(el,key) in dominios.tipoponente" :key="key">
                            <input type="radio" :value="key" name="tipoponente" :id="'tipoponente'+key"
                                v-model="data.speaker_of">
                            <label :for="'tipoponente'+key">{{el}}</label>
                        </div>
                    </div>

                    <div class="contenedor-input" v-if="data.speaker == 1">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <year-datepicker :class="classes" :label="'¿En que año?'" :minpage="new Date()"
                                v-model="data.speaker_date" :name="'speaker_date'">
                            </year-datepicker>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>


                    <div class="contenedor-input row-start fww">
                        <h5>Beca</h5>
                        <input type="radio" :value="1" name="beca" id="beca_si" v-model="data.grant">
                        <label for="beca_si">Sí</label>
                        <input type="radio" :value="0" name="beca" id="beca_no" v-model="data.grant">
                        <label for="beca_no">No</label>
                    </div>

                    <div class="contenedor-input row-start fww" v-if="data.grant">
                        <h5>Tipo de beca:</h5>
                        <div class="full" v-for="(el,key) in dominios.tipobecas" :key="key">
                            <input type="radio" :value="key" name="tipobeca" :id="'tipobeca'+key"
                                v-model="data.grant_of">
                            <label :for="'tipobeca'+key">{{el}}</label>
                        </div>
                    </div>

                    <div class="contenedor-input" v-if="data.grant == 1">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <year-datepicker :class="classes" :label="'¿En que año?'" :minpage="new Date()"
                                v-model="data.grant_date" :name="'grant_date'">
                            </year-datepicker>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input row-start fww">
                        <h5>Si quieres que otros Elumni contacten contigo indícanos vías de contacto disponibles: </h5>
                        <div class="full" v-for="(el,key) in dominios.contactar" :key="key">
                            <input type="radio" :value="key" name="contactar" :id="'contactar'+key"
                                v-model="data.allow_others">
                            <label :for="'contactar'+key">{{el}}</label>
                        </div>
                    </div>

                    <div class="contenedor-input">

                        <input autocomplete="off" type="text" name="direccion"
                            placeholder="Indícanos cualquier otra beca, premios, reconocimientos, méritos académicos u observaciones"
                            v-model="data.awards">

                    </div>




                    <div class="full-width row-center">
                        <button class="boton-azul" :loading="loading">Guardar</button>
                    </div>


                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>

    import { mapGetters, mapActions } from 'vuex';


    import moment from 'moment';


    export default {
        name: 'mis-datos-elumni',
        mounted() {
            this.setData();
        },
        data: () => ({
            data: {},
            loading: false
        }),
        computed: {
            ...mapGetters({
                dominios: 'getDominios'
            })
        },
        methods: {
            ...mapActions(['saveAlumni', 'getAlumni']),
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;

                        this.data.member_elu_tribunal_date = moment(this.data.member_elu_tribunal_date).format("YYYY/MM/DD");
                        this.data.fds_selection_be_date = moment(this.data.fds_selection_be_date).format("YYYY/MM/DD");
                        this.data.speaker_date = moment(this.data.speaker_date).format("YYYY/MM/DD");
                        this.data.grant_date = moment(this.data.grant_date).format("YYYY/MM/DD");

                        this.saveAlumni(this.data).then(() => {
                            this.loading = false;
                        })
                    }
                });
            },
            setData() {
                this.getAlumni().then(r => {
                    this.data = r;
                    this.data.member_elu_tribunal_date = moment(r.member_elu_tribunal_date).format("YYYY");
                    this.data.fds_selection_be_date = moment(r.fds_selection_be_date).format("YYYY");
                    this.data.speaker_date = moment(r.speaker_date).format("YYYY");
                    this.data.grant_date = moment(r.grant_date).format("YYYY");
                });
            }
        }
    }
</script>