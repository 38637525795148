<template>
 
    <div class="cover-login js-registro">

        <div class="contenedor-popup row-center">
    
    
            <div class="popup">
                
                <div class="paso">

                    <h2 class="titulo left">Mis datos académicos</h2>

                    <academico  :values="el" :key="key" v-for="(el,key) in data" @refresh="setData" :pos="key"></academico>

                    <p v-if="data.length == 0 && !shownew">Aún no tienes ningún dato añadido</p>

                    <div class="full-width row-center contenedor-boton" v-if="!shownew">
                        <a class="boton-azul" @click="shownew = true" :loading="loading">Añadir nuevo</a>
                    </div>

                    <academico :showedit="shownew" v-if="shownew" @refresh="setData" :onlyufv="true" :pos="'new'"></academico>
    
                </div>
            </div>
    
        </div>
    </div>
    
</template>

<script>

    import { mapGetters, mapActions } from 'vuex';

    import router from '@/router';

    import academico from '@/components/academico-el'

    export default {
        name: 'mis-datos-personales',
        mounted() {
            this.setData();
        },
        data: () => ({
            data : {

            },
            loading: false,
            shownew: false
        }),
        computed: {
            ...mapGetters({
                dominios: 'getDominios'
            })
        },
        methods: {
            ...mapActions(['saveAlumni', 'getAcademicos']),
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        this.saveAlumni(this.data).then(r => {
                            this.loading = false;
                            if (r) {
                                router.push({
                                    name: 'login'
                                })
                            }
                        })
                    }
                });
            },
            setData() {
                this.shownew = false;

                this.data = [];

                this.getAcademicos().then(r => {
                    this.data = r;
                });
            }
        },
        components : {
            'academico' : academico
        }
    }
</script>

<style lang="scss" scoped>
    .js-registro .popup{
        width: 100%;
    }
</style>