<template>
    <div class="wrapper-registro cover-login">
        <div class="popup">
            <ValidationObserver ref="form" v-slot="{failed,valid}">
                <form class="paso row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="off">
                    <h2 class="titulo left">Mis datos personales</h2>

                    <div class="imagen-input-container">
                        <p>Mi foto de perfil</p>
                        <avatar v-model="data.image"></avatar>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="nombre" placeholder="Nombre" v-model="data.name"
                                :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="apellido-uno" placeholder="Apellido 1"
                                v-model="data.surname" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>


                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="apellido-dos" placeholder="Apellido 2"
                                v-model="data.surname_2" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <select id="nacionalidad" name="nacionalidad" v-model="data.nationality" class="pretty"
                                :class="classes">
                                <option name="myBrowser" placeholder="" value="">Nacionalidad</option>
                                <option :key="key" v-for="(pais,key) in dominios.paises" name="myBrowser" :value="pais">
                                    {{pais}}</option>
                            </select>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <select id="tipodocumento" name="tipodocumento" v-model="data.document_type" class="pretty"
                                :class="classes">
                                <option name="myBrowser" placeholder="" value="">Tipo de documento</option>
                                <option :key="key" v-for="(tipo,key) in dominios.tiposdocumento" name="myBrowser"
                                    :value="key">
                                    {{tipo}}</option>
                            </select>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="dni" placeholder="DNI" v-model="data.document"
                                :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="email" name="mail" placeholder="Mail" v-model="data.email"
                                :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="movil" placeholder="Móvil" v-model="data.phone"
                                :class="classes">
                            <p class="msg-info-tel">Por favor no utilices ni espacios ni símbolos, si tienes un teléfono
                                extranjero indica el
                                prefijo con 00 en vez de con +</p>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half row-start fww">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <h5>Sexo</h5>
                            <input type="radio" :value="'0'" name="sexo" id="hombre" v-model="data.gender">
                            <label :class="classes" for="hombre">Hombre</label>
                            <input type="radio" :value="'1'" name="sexo" id="mujer" v-model="data.gender">
                            <label :class="classes" for="mujer">Mujer</label>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <datepicker :label="'Fecha de nacimiento'" v-model="data.birthday_date" :name="'fecha'"
                                :class="classes">
                            </datepicker>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>


                    <div class="contenedor-input">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="direccion" placeholder="Dirección"
                                v-model="data.address" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <select id="paisresidencia" name="paisresidencia" v-model="data.country" class="pretty"
                                :class="classes">
                                <option name="myBrowser" placeholder="" value="">País de residencia</option>
                                <option :key="key" v-for="(pais,key) in dominios.paises" name="myBrowser" :value="pais">
                                    {{pais}}</option>
                            </select>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half" v-if="data.country != 'España'">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="provincia" placeholder="Provincia"
                                v-model="data.province" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half"  v-if="data.country == 'España'">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <select id="provincia" name="provincia" v-model="data.province" class="pretty"
                                :class="classes">
                                <option name="myBrowser" placeholder="" value="">Provincia</option>
                                <option :key="key" v-for="(provincia,key) in dominios.provincias" name="myBrowser" :value="provincia">
                                    {{provincia}}</option>
                            </select>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>



                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="cp" placeholder="Código Postal"
                                v-model="data.cp" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>


                    <h2 class="titulo left">Perfiles sociales</h2>

                    <div class="contenedor-input">
                        <input autocomplete="off" type="text" name="facebook" placeholder="Facebook"
                            v-model="data.facebook">
                    </div>

                    <div class="contenedor-input">
                        <input autocomplete="off" type="text" name="linkedin" placeholder="Linkedin"
                            v-model="data.linkedin">
                    </div>

                    <div class="contenedor-input">
                        <input autocomplete="off" type="text" name="instagram" placeholder="Instagram"
                            v-model="data.instagram">
                    </div>

                    <div class="full-width row-center">
                        <button class="boton-azul" :loading="loading">Enviar</button>
                    </div>


                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>

    import { mapGetters, mapActions } from 'vuex';


    import avatar from '@/components/avatar-input';

    

    export default {
        name: 'mis-datos-personales',
        mounted() {
            this.setData();
        },
        data: () => ({
            data: {

                'document': '',
                'document_type': '',
                'email': '',
                'password': '',
                'password_confirmation': '',
                'name': '',
                'surname': '',
                'surname_2': '',
                'phone': '',
                'birthday_date': '',
                'gender': '',
                'nationality': '',
                'country': '',
                'province': '',
                'contact': '',
            },
            loading: false
        }),
        computed: {
            ...mapGetters({
                dominios: 'getDominios'
            })
        },
        methods: {
            ...mapActions(['saveAlumni', 'getAlumni']),
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        this.saveAlumni(this.data).then(() => {
                            this.loading = false;
                        })
                    }
                });
            },
            setData() {
                this.getAlumni().then(r => {
                    this.data = r;
                });
            }
        },
        components : {
            'avatar' : avatar
        }
    }
</script>