<template>
    <div class="microform" :edit="edit">

        <template v-if="!edit && data">
            <h3>{{data.sector.name}}</h3>
            <h2>{{data.position}} en {{data.company}}</h2>
            <p>{{data.start_date | date}} - <template v-if="data.end_date">{{data.end_date | date}}</template> <template v-if="!data.end_date">Actualidad</template></p>
        </template>

        <a class="editar" @click="edit = true">editar</a>
        <a class="eliminar" @click="showerase = true">Eliminar</a>


        <template v-if="edit">
            <ValidationObserver ref="form" v-slot="{failed,valid}">

                <form class="paso row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="off">


                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|max:50" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="puesto" placeholder="Puesto"
                                v-model="data.position" :class="classes" max="50">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|max:50" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="empresa" placeholder="Empresa"
                                v-model="data.company" :class="classes" max="50">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <datepicker :label="'Fecha de Inicio'" v-model="data.start_date" :name="'fecha'"
                                :class="classes">
                            </datepicker>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <div class="toggle">
                            <input type="checkbox" id="actual" v-model="data.actual">
                            <label for="actual">Actualmente</label>
                        </div>
                        <datepicker :label="'Fecha de fin'" v-model="data.end_date" :name="'fecha'">
                        </datepicker>
                    </div>


                    <div class="contenedor-input">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <autocomplete :class="classes" :name="'sector'" :label="'Sector'" :type="'sector'"
                                v-model="data.sector" :idkey="'id'"></autocomplete>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>



                    <div class="full-width row-center contenedor-botones">
                        <button class="boton-azul" :loading="loading">Guardar</button>
                        <button class="boton-gris" @click="cancel">Cancelar</button>
                    </div>

                </form>

            </ValidationObserver>

        </template>

        <popup-confirmacion v-if="showerase" :loading="loadingerase" :how="'borrarprofesional'" @accept="requestErase"
        @cancel="showerase = false" :isnegative="true"></popup-confirmacion>

    </div>
</template>


<script>

    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'profesional',
        props: ['values', 'showedit'],
        mounted() {
            if (this.values) {
                this.data = this.values;
                this.data.actual = !this.values.end_date;
            }
            this.edit = this.showedit;
        },
        data: () => ({
            data: {
                company: '',
                position: '',
                start_date: '',
                end_date: '',
                sector: '',
                actual : false
            },
            edit: false,
            loading: false, 
            showerase: false
        }),
        methods: {
            ...mapActions(['deleteProfesional', 'saveProfesional']),
            requestErase() {
                let data = [this.data.dataId];

                data = { dataId: data }
                this.deleteProfesional(data).then(r => {
                    if(r){
                        this.$emit('refresh')
                    }
                })
            },
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;

                        let data = JSON.parse(JSON.stringify(this.data));

                        if(typeof data.sector == 'object'){
                            data.sector = data.sector_id;
                        }

                        this.saveProfesional(data).then(r => {
                            this.loading = false;
                            if (r) {
                                this.edit = false;
                                this.$emit('refresh')
                            }
                        })
                    }
                });
            },
            cancel(){
                this.$emit('cancel');
                this.edit = false;
            }
        },
        computed: {
            ...mapGetters({
                dominios: 'getDominios'
            })
        },
        watch : {
            'data.actual'(){
                if(this.data.actual){
                    this.data.end_date = '';
                }
            }
        }


    }
</script>