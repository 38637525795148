<template>
    <div class="wrapper-registro cover-login">
        <div class="popup">
            <ValidationObserver ref="form" v-slot="{failed,valid}">
                <form class="paso row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="off">
                    <h2 class="titulo left">Mis datos Internacional</h2>


                    <div class="toggle">
                        <input type="checkbox" id="internacional" v-model="data.internacional_bowl" @change="requestSaveAlumni">
                        <label for="internacional">Soy alumno internacional</label>
                    </div>

                    <template v-if="data.internacional_bowl">

                        <div class="contenedor-input row-start fww">
                            <h5>¿Cuál es tu relación con la UFV?</h5>
                            <div class="full" v-for="(n, key) in dominios.relacionufv" :key="key">
                                <input type="radio" :value="key" name="international_relation" :id="'relacion-'+key"
                                    v-model="data.international_relation">
                                <label :for="'relacion-'+key">{{n}}</label>
                            </div>
                        </div>

                        <div class="contenedor-input">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <year-datepicker :class="classes"
                                    :label="'¿Cuándo participaste o hiciste tu intercambio con la UFV?'"
                                    :minpage="new Date()" v-model="data.international_year"
                                    :name="'international_year'">
                                </year-datepicker>
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <input autocomplete="off" type="text" name="buddy"
                                    placeholder="Indícanos el nombre de tu buddy en la UFV"
                                    v-model="data.internaltional_buddy" :class="classes">
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>



                        <div class="full-width row-center">
                            <button class="boton-azul" :loading="loading">Guardar</button>
                        </div>


                        <figure>Mis idiomas</figure>

                        <idioma v-for="(el,key) in langs" :values="el" :key="key"></idioma>

                        <p v-if="langs.length == 0 && !shownew">Aún no tienes ningún idioma añadido</p>

                        <div class="contenedor-boton full-width row-center" v-if="!shownew">
                            <a class="boton-azul" @click="shownew = true" :loading="loading">Añadir nuevo</a>
                        </div>

                        <idioma v-if="shownew" :showedit="true" @cancel="shownew = false" @refresh="setLangs"></idioma>

                    </template>


                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>

    import { mapGetters, mapActions } from 'vuex';


    import idioma from '@/components/idioma-el';


    export default {
        name: 'mis-datos-elumni',
        mounted() {
            this.setData();
            this.setLangs();
        },
        data: () => ({
            data: {},
            langs: '',
            loading: false,
            shownew: false
        }),
        computed: {
            ...mapGetters({
                dominios: 'getDominios'
            })
        },
        methods: {
            ...mapActions(['saveAlumni', 'getAlumni', 'getIdioma']),
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        this.saveAlumni(this.data).then(() => {
                            this.loading = false;
                        })
                    }
                });
            },
            setData() {
                this.getAlumni().then(r => {
                    this.data = r;
                });
            },
            setLangs() {
                this.getIdioma().then(r => {
                    this.langs = r;
                });
                this.shownew = false;
            },
            requestSaveAlumni() {
                this.saveAlumni(this.data);
            }
        },
        components: {
            'idioma': idioma
        }
    }
</script>